export default {
    methods: {
        generatePlayers(addedPlayers) {
            if(this.multiplayer) {
                if(this.userData == null) {
                    router.push({ path: '/' });
                    return location.reload();
                }
            }

            addedPlayers.forEach((player, index) => {
                this.players.push({
                    position: index,
                    color: player.color,
                    name: player.name,
                    riskyAi: player.riskyAi,
                    ai: player.ai,
                    turnsBeforeYou: index,
                    buildOrder: index,
                    tradeGet: [
                        { type: 'lumber', amount: 0 },
                        { type: 'brick', amount: 0 },
                        { type: 'wool', amount: 0 },
                        { type: 'grain', amount: 0 },
                        { type: 'ore', amount: 0 },
                        { type: 'cloth', amount: 0 },
                        { type: 'coin', amount: 0 },
                        { type: 'paper', amount: 0 },
                    ],
                    offerOpponents: false,
                    // accepted, rejected, counter offer
                    offerAnswer: null,

                    handCards: [
                        { type: 'paper', amount: 0, slideIn: 0, slideOut: 0, selected: 0, tradeCost: 4, save: 0, throw: 0, stolen: 0, cardValue: 30, group: 'commodity', },
                        { type: 'coin', amount: 0, slideIn: 0, slideOut: 0, selected: 0, tradeCost: 4, save: 0, throw: 0, stolen: 0, cardValue: 20, group: 'commodity', },
                        { type: 'cloth', amount: 0, slideIn: 0, slideOut: 0, selected: 0, tradeCost: 4, save: 0, throw: 0, stolen: 0, cardValue: 25, group: 'commodity', },
                        { type: 'ore', amount: 0, slideIn: 0, slideOut: 0, selected: 0, tradeCost: 4, save: 0, throw: 0, stolen: 0, cardValue: 40, group: 'resource', },
                        { type: 'grain', amount: 0, slideIn: 0, slideOut: 0, selected: 0, tradeCost: 4, save: 0, throw: 0, stolen: 0, cardValue: 35, group: 'resource', },
                        { type: 'wool', amount: 0, slideIn: 0, slideOut: 0, selected: 0, tradeCost: 4, save: 0, throw: 0, stolen: 0, cardValue: 10, group: 'resource', },
                        { type: 'brick', amount: 0, slideIn: 0, slideOut: 0, selected: 0, tradeCost: 4, save: 0, throw: 0, stolen: 0, cardValue: 5, group: 'resource', },
                        { type: 'lumber', amount: 0, slideIn: 0, slideOut: 0, selected: 0, tradeCost: 4, save: 0, throw: 0, stolen: 0, cardValue: 15, group: 'resource', },
                    ],
                    goldmineAmount: 0,
                    goldmineChoises: [
                        { type: 'ore', amount: 0, },
                        { type: 'grain', amount: 0, },
                        { type: 'wool', amount: 0, },
                        { type: 'brick', amount: 0, },
                        { type: 'lumber', amount: 0, },
                    ],
                    showIncomingHandCards: true,
                    showOutgoingHandCards: true,
                    // prgr
                    progressCards: [
                        // { deck: 'cloth', label: 'merchant'},
                        // { deck: 'cloth', label: 'trade monopoly' },
                        // { deck: 'cloth', label: 'resource monopoly' },
                        // { deck: 'cloth', label: 'commercial harbor' },
                        // { deck: 'cloth', label: 'master merchant' },
                        // { deck: 'cloth', label: 'merchant fleet' },
                        // { deck: 'coin', label: 'spy' },
                        // { deck: 'coin', label: 'deseter' },
                        // { deck: 'coin', label: 'bishop' },
                        // { deck: 'coin', label: 'diplomat' },
                        // { deck: 'coin', label: 'wedding' },
                        // { deck: 'coin', label: 'saboteur' },
                        // { deck: 'coin', label: 'warlord' },
                        // { deck: 'coin', label: 'intrigue' },
                        // { deck: 'paper', label: 'alchemist' },
                        // { deck: 'paper', label: 'smith' },
                        // { deck: 'paper', label: 'crane' },
                        // { deck: 'paper', label: 'irrigation' },
                        // { deck: 'paper', label: 'mining' },
                        // { deck: 'paper', label: 'inventor' },
                        // { deck: 'paper', label: 'road building' },
                        // { deck: 'paper', label: 'medecine' },
                        // { deck: 'paper', label: 'engineer' },
                        // { deck: 'basic', label: 'knight' },
                        // { deck: 'basic', label: 'monopoly' },
                        // { deck: 'basic', label: 'year of plenty' },
                        // { deck: 'basic', label: 'road building' },
                        // { deck: 'basic', label: 'point' },
                    ],
                    progress: [
                        { type: 'cloth', level: this.randomNumber(0,0), metropolis: false, },
                        { type: 'coin', level: this.randomNumber(0,0), metropolis: false, },
                        { type: 'paper', level: this.randomNumber(0,0), metropolis: false, },
                    ],
                    // longest road, cloth, paper, coin, war-points
                    fightFor: [],
                    // fightFor: ['paper'],
                    giveUp: [],
                    // pointCards: [{ label: 'point', points: 1 },{ label: 'played-knight', points: 0 },{ label: 'largest-army', points: 2 },{ label: 'played-knight', points: 0 },{ label: 'island', points: 1 }, { label: 'war', points: 1 }, { label: 'island', points: 1 }, { label: 'paper', points: 1 }, { label: 'coin', points: 1 },{ label: 'played-knight', points: 0 },{ label: 'played-knight', points: 0 }],
                    pointCards: [],
                    pieces: {
                        houses: 5,
                        cities: 4,
                        roads: 15,
                        boats: 15,
                        citywalls: 3,
                        knights: [1, 1, 2, 2, 3, 3],
                    },
                    points: 0,
                    potential: 0,

                    modalMode: false,
                    modalType: null,
                    hideModal: false,

                    harbors: [],
                    tempHarbors: [],

                    highlightedSpots: [],

                    highlightMyPoints: false,
                    highlightMyHandCards: false,
                    highlightMyProgressCards: false,

                    getAqueductCard: true,

                    dices: {
                        whiteDiceRoll: null,
                        redDiceRoll: null,

                        dicesRolling: false,
                        dicesRollAnimation: false,
                        whiteDiceRolling: false,
                        redDiceRolling: false,

                        canBeStartingPlayer: true,
                        fadeAway: false,
                    },
                    roadLength: 0,
                    boatMovedThisTurn: false,
                    basicProgressCardPlayedThisTurn: false,

                    is_open: 0,

                    waitingForMe: false,
                    idleTimer: 0,
                })
            });
        },
    }
 }
